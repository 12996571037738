<template>
  <div class="content">
    <div class="page">
      <div class="img_demo">
        <img src="" alt="" />
        <div class="right">
          <h2>大数据分析</h2>
          <div class="message">
            基于数据源提供的数据，利用数据模型可视化分析后台，从不同维度对各种数据指标进行综合分析、分类对比，并对数据进行筛选、条数限制、数据公式计算等操作，生成不同的度量数据模型。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 10%;
  min-height: calc(100vh - 387px);
  .page {
    margin-top: 50px;
  }
  .img_demo {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    > img {
      display: inline-block;
      width: 50%;
      background: url("../../../../assets/image/ruralImg/img5.png") no-repeat;
      background-size: 100% 100%;
    }
    h2 {
      font-weight: bold;
      margin: 50px 30px 10px 50px;
    }
    .message {
      color: #6b6b6b;
      font-size: 16px;
      margin: 20px 30px 10px 50px;
    }
    .right {
      width: 50%;

      height: 400px;
      background-color: #fff;
    }
  }
}
</style>